<template>
  <base-layout-two page-title="Room Types" page-default-back-link="/more" content-class="background-white">
    <section class="padding-x-16">
      <h1>Room Types</h1>
      <ion-button class="regular-button" expand="block" router-link="/room_types/create"
        >New Room Type</ion-button
      >
    </section>

    <ion-list class="padding-x-16">
      <room-type-list-item
        v-for="room_type in room_types"
        :key="room_type.id"
        :router-link="`/room_types/${room_type.id}`"
        :room_type="room_type"
      >
      </room-type-list-item>
    </ion-list>
  </base-layout-two>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue";
import RoomTypeListItem from "@/components/roomTypes/RoomTypeListItem.vue";

export default {
  components: {
    IonList,
    IonButton,
    RoomTypeListItem,
  },

  data() {
    return {
      room_types: [],
    };
  },

  ionViewWillEnter() {
    this.fetchRoomTypes();
  },

  methods: {
    async fetchRoomTypes() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/room_types`)
        .then((response) => {
          this.room_types = response.data.room_types;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>